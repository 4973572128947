<script setup lang="ts">
import { useCmsBlock } from "#imports";
import type {CmsBlockMetrics} from "~/types/cmsBlockTypes";
import type {BlockType} from "@shopware-pwa/composables-next";

const props = defineProps<{
  content: CmsBlockMetrics;
}>();

const { getSlotContent } = useCmsBlock(props.content);

const leftContent = getSlotContent("left");
const rightContent = getSlotContent("right");
const bottomContent = getSlotContent("bottom");
</script>
<template>
  <v-row no-gutters>
    <v-col cols="12" sm="6" class="pr-sm-4" order="2" order-sm="1">
      <CmsGenericElement :content="leftContent" />
    </v-col>
    <v-col cols="12" sm="6" class="pl-sm-4 mb-4" order="1" order-sm="2">
      <CmsGenericElement :content="rightContent" />
    </v-col>
    <v-col cols="12" order="3">
      <v-divider class="border-opacity-100 my-8 border-gray700"></v-divider>
      <CmsGenericElement :content="bottomContent" />
    </v-col>
  </v-row>
</template>
